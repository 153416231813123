import { getIn } from "formik";
import React from "react";
import { TextField, MenuItem } from "@mui/material";

export const MultiSelectFormField: React.FC<any> = ({
    field,
    form,
    options,
    ...props
}) => {
    const errorText =
        getIn(form.touched, field.name) && getIn(form.errors, field.name);

    const handleChange = (event: React.ChangeEvent<{ value: string[] }>) => {
        const selectedValues = event.target.value;
        form.setFieldValue(field.name, selectedValues);
    };

    return (
        <TextField
            {...props}
            id="outlined-basic"
            variant="outlined"
            helperText={errorText}
            error={!!errorText}
            select
            SelectProps={{
                multiple: true,
                value: field.value || [],
                onChange: handleChange,
                sx: { borderRadius: 2 },
            }}
        >
            {options.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default MultiSelectFormField;
