import fetcher from "../helpers/fetcher";

export async function FindTemplate(id, office_id) {
  let path = `/template/${id}?office_id=${office_id}`;

  let request = await fetcher(path, "GET");

  let content = request.json();

  return content;
}

// export async function FindAllTemplates(office_id) {
//   let path = `/template?office_id=${office_id}`;

//   let request = await fetcher(path, "GET");

//   let content = request.json();

//   return content;
// }

export async function FindTemplatesWithPaginationAndSearch(office_id, page, debouncedSearchTerm = "") {
  let path = `/template?office_id=${office_id}&page=${page}&search=${debouncedSearchTerm}`;

  let request = await fetcher(path, "GET");

  let content = request.json();

  return content;
}


export async function UpdateTemplates(data, id, office_id) {
  let path = `/template/${id}?office_id=${office_id}`;

  let request = await fetcher(path, "PUT", data);

  let content = request.json();

  return content;
}

export async function CreateNewTemplate(data, office_id) {
  let path = `/template?office_id=${office_id}`;

  let request = await fetcher(path, "POST", data);

  let content = request.json();

  return content;
}

export async function DeleteTemplate(id, office_id) {
  let path = `/template/${id}?office_id=${office_id}`;

  let request = await fetcher(path, "DELETE");

  let content = request.json();

  return content;
}
