import fetcher from "../helpers/fetcher";

export default async function ReqUsers(page, debouncedSearchTerm = "") {
  let path = `/user?page=${page}&search=${debouncedSearchTerm}`;

  let request = await fetcher(path, "GET");

  let content = request.json();

  return content;
}

export async function GetAllUsers() {
  let path = `/user`;

  let request = await fetcher(path, "GET");

  let content = request.json();

  return content;
}

export async function FindUser(id) {
  let path = `/user/${id}`;

  let request = await fetcher(path, "GET");

  let content = request.json();

  return content;
}

export async function AddUsers(data) {
  let path = `/user`;

  let request = await fetcher(path, "POST", data);

  let content = request.json();

  return content;
}

export async function UpdateUsers(data, id) {
  let path = `/user/${id}`;

  let request = await fetcher(path, "PUT", data);

  let content = request.json();

  return content;
}

export async function UpdateUsersPass(data, id) {
  let path = `/user/password/${id}`;

  let request = await fetcher(path, "PUT", data);

  let content = request.json();

  return content;
}

export async function DeleteUsers(id) {
  let path = `/user/${id}`;

  let request = await fetcher(path, "DELETE");

  let content = request.json();

  return content;
}
