import fetcher from "../helpers/fetcher";

export async function ReqLogs(page, debouncedSearchTerm = "") {
  let path = `/logs?page=${page}&search=${debouncedSearchTerm}`;

  let request = await fetcher(path, "GET");

  let content = request.json();

  return content;
}

export async function addLog(data) {
  let path = `/logs`;

  let request = await fetcher(path, "POST", data);

  let content = request.json();

  return content;
}
