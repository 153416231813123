export default async function fetcher(
  path,
  method,
  body = null,
  is_reload = true,
  is_form_data = false
) {
  let headers = {};
  let token = localStorage.getItem("token");

  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    if (is_form_data) {
      delete headers["Content-Type"];
    }
  }
  const response = await fetch(process.env.REACT_APP_REQUEST_URL + path, {
    method,
    headers,
    body,
  });

  if (is_reload && response.status === 401) {
    window.location.reload();
  }

  return response;
}
