import * as React from "react";
import { Formik, Field, Form } from "formik";
import { TextFormField } from "../../components/textform/TextFormField.tsx";
import * as yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import YupPassword from "yup-password";
import MultiSelectFormField from "../../components/multiselectform/MultiSelectFormField.tsx";
import CircularProgress from "@mui/material/CircularProgress";
import { addOffice } from "../../services/office.js";
import { GetAllUsers } from "../../services/user.js";
import { useEffect } from "react";
import { FUNCTIONALITIES } from "../../constants/functionalities.js";

YupPassword(yup);

export const validation = yup.object().shape({
  office_name: yup.string().required("Office name is required"),
  siiv_username: yup.string().required("SIIV Username is required"),
  siiv_password: yup.string().required("SIIV Password is required"),
  siiv_office: yup.string().required("SIIV Office is required"),
  username: yup.string().required("Username is required"),
  functionality: yup.array().min(1, "At least one functionality is required"),
  aes_username: yup.string().required("AES Username is required"),
  aes_password: yup.string().required("AES Password is required"),
  aes_office: yup.string().required("AES Office is required"),
});

export default function CreateOffice() {
  const [error, setError] = useState(false);
  const [errorDescription, setErrorDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const functionalitiesList = Object.values(FUNCTIONALITIES).map((value) => ({
    label: value,
    value,
  }));

  const navigate = useNavigate();

  useEffect(() => {
    getUsers();
  }, []);

  const handleError = (description) => {
    setError(true);
    setErrorDescription(description);
  };

  async function getUsers() {
    try {
      const content = await GetAllUsers();
      if (content.error) {
        handleError(content.error);
      } else if (content.status === "OK") {
        const filteredUsers = content.data.filter(
          (user) => !user.role.includes("ROLE_ADMIN")
        );
        const formattedUsers = filteredUsers.map((user) => {
          return { value: user.ID, label: user.name };
        });
        setUsers(formattedUsers);
      } else {
        handleError("Something went wrong");
      }
    } catch {
      handleError("Something went wrong");
    }
  }

  const form = [
    {
      name: "office_name",
      label: "Office Name",
      disabled: false,
      component: TextFormField,
      style: {
        width: "49%",
      },
    },
    {
      name: "siiv_username",
      label: "SIIV Username",
      disabled: false,
      component: TextFormField,
      style: {
        width: "49%",
      },
    },

    {
      name: "siiv_password",
      label: "SIIV Password",
      disabled: false,
      component: TextFormField,
      style: {
        width: "49%",
      },
    },
    {
      name: "siiv_office",
      label: "SIIV Office",
      disabled: false,
      component: TextFormField,
      style: {
        width: "49%",
      },
    },
    {
      name: "aes_username",
      label: "AES Username",
      disabled: false,
      component: TextFormField,
      style: {
        width: "49%",
      },
    },

    {
      name: "aes_password",
      label: "AES Password",
      disabled: false,
      component: TextFormField,
      style: {
        width: "49%",
      },
    },
    {
      name: "aes_office",
      label: "AES Office",
      disabled: false,
      component: TextFormField,
      style: {
        width: "49%",
      },
    },
    {
      name: "username",
      label: "Username",
      disabled: false,
      component: TextFormField,
      select: true,
      options: users,
      style: {
        width: "49%",
      },
    },
    {
      name: "functionality",
      label: "Functionality",
      disabled: false,
      multiple: true,
      component: MultiSelectFormField,
      select: true,
      options: functionalitiesList,
      style: {
        width: "49%",
      },
    },
    {
      name: "export_pad_url",
      label: "Export PAD Url",
      disabled: false,
      component: TextFormField,
      style: {
        width: "49%",
      },
    },
    {
      name: "create_export_pad_url",
      label: "Create Export PAD Url",
      disabled: false,
      component: TextFormField,
      style: {
        width: "49%",
      },
    },
  ];

  const submitForm = async (values) => {
    const data = {
      office_name: values.office_name,
      siiv_username: values.siiv_username,
      siiv_password: values.siiv_password,
      siiv_office: values.siiv_office,
      aes_username: values.aes_username,
      aes_password: values.aes_password,
      aes_office: values.aes_office,
      functionality: values.functionality,
      export_pad_url: values.export_pad_url,
      create_export_pad_url: values.create_export_pad_url,
      user_id: values.username,
    };

    var jsonData = JSON.stringify(data);
    try {
      setLoading(true);
      const content = await addOffice(jsonData);

      if (content.error) {
        handleError(content.error);
      } else if (content.status === "OK") {
        navigate("/office");
      }
    } catch {
      handleError("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}
      >
        {error && (
          <div className="error-container">
            <span className="error-text">{errorDescription}</span>
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="form-container">
          <Formik
            validationSchema={validation}
            initialValues={{
              office_name: "",
              siiv_username: "",
              siiv_password: "",
              siiv_office: "",
              aes_username: "",
              aes_password: "",
              aes_office: "",
              functionality: [],
              username: "",
              export_pad_url: "",
              create_export_pad_url: "",
            }}
            onSubmit={(values) => {
              submitForm(values);
            }}
          >
            {() => (
              <Form
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className="form-position">
                  {form.map((snapshot, key) => {
                    return <Field {...snapshot} key={key} />;
                  })}
                </div>
                <button className="submit-form" type="submit">
                  {loading ? <CircularProgress size={24} /> : "Create Office"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
