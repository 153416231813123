import fetcher from "../helpers/fetcher";

export async function FindOffice(id) {
  let path = `/office/${id}`;

  let request = await fetcher(path, "GET");

  let content = request.json();

  return content;
}

export default async function UpdateOffices(data, id) {
  let path = `/office/${id}`;

  let request = await fetcher(path, "PUT", data);

  let content = request.json();

  return content;
}

export async function ReqOffices(page, debouncedSearchTerm = "") {
  let path = `/office?page=${page}&search=${debouncedSearchTerm}`;

  let request = await fetcher(path, "GET");

  let content = request.json();

  return content;
}

export async function addOffice(data) {
  let path = `/office`;

  let request = await fetcher(path, "POST", data);

  let content = request.json();

  return content;
}

export async function DeleteOffices(id) {
  let path = `/office/${id}`;

  let request = await fetcher(path, "DELETE");

  let content = request.json();

  return content;
}
