import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/fonts/Segoe UI Bold Italic.ttf";
import "./assets/fonts/Segoe UI Bold.ttf";
import "./assets/fonts/Segoe UI Italic.ttf";
import "./assets/fonts/Segoe UI.ttf";
import AppRouter from "./approuter";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<AppRouter />);
