import * as React from "react";
import { Formik, Field, Form } from "formik";
import { TextFormField } from "../../components/textform/TextFormField.tsx";
import * as yup from "yup";
import UpdateOffices, { FindOffice } from "../../services/office.js";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MultiSelectFormField from "../../components/multiselectform/MultiSelectFormField.tsx";
import CircularProgress from "@mui/material/CircularProgress";
import { FUNCTIONALITIES } from "../../constants/functionalities.js";

export const validation = yup.object().shape({
  siiv_username: yup.string().required("Siiv Username is required"),
  siiv_password: yup.string().required("Siiv Password is required"),
  siiv_office: yup.string().required("Siiv Office is required"),
  office_name: yup.string().required("Office Name is required"),
  functionality: yup.array().min(1, "At least one functionality is required"),
  aes_username: yup.string().required("Aes Username is required"),
  aes_password: yup.string().required("Aes Password is required"),
  aes_office: yup.string().required("Aes Office is required"),
});

export default function UpdateOffice() {
  const [error, setError] = useState(false);
  const [errorDescription, setErrorDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [office, setOffice] = useState(null);
  const navigate = useNavigate();
  const functionalitiesList = Object.values(FUNCTIONALITIES).map((value) => ({
    label: value,
    value,
  }));

  const form = [
    {
      name: "siiv_username",
      label: "Siiv Username",
      disabled: false,
      component: TextFormField,
      style: {
        width: "49%",
      },
    },
    {
      name: "siiv_password",
      label: "Siiv Password",
      disabled: false,
      component: TextFormField,
      style: {
        width: "49%",
      },
    },
    {
      name: "siiv_office",
      label: "Siiv Office",
      disabled: false,
      component: TextFormField,
      style: {
        width: "49%",
      },
    },
    {
      name: "office_name",
      label: "Office Name",
      disabled: false,
      component: TextFormField,
      style: {
        width: "49%",
      },
    },
    {
      name: "functionality",
      label: "Functionality",
      disabled: false,
      multiple: true,
      component: MultiSelectFormField,
      select: true,
      options: functionalitiesList,
      style: {
        width: "49%",
      },
    },
    {
      name: "aes_username",
      label: "Aes Username",
      disabled: false,
      component: TextFormField,
      style: {
        width: "49%",
      },
    },
    {
      name: "aes_password",
      label: "Aes Password",
      disabled: false,
      component: TextFormField,
      style: {
        width: "49%",
      },
    },
    {
      name: "aes_office",
      label: "Aes Office",
      disabled: false,
      component: TextFormField,
      style: {
        width: "49%",
      },
    },
    {
      name: "export_pad_url",
      label: "Export PAD Url",
      disabled: false,
      component: TextFormField,
      style: {
        width: "49%",
      },
    },
    {
      name: "create_export_pad_url",
      label: "Create Export PAD Url",
      disabled: false,
      component: TextFormField,
      style: {
        width: "49%",
      },
    },
  ];

  const handleError = (description) => {
    setError(true);
    setErrorDescription(description);
  };

  const submitForm = async (values) => {
    const data = {
      siiv_username: values.siiv_username,
      siiv_password: values.siiv_password,
      siiv_office: values.siiv_office,
      office_name: values.office_name,
      functionality: values.functionality,
      aes_username: values.aes_username,
      aes_password: values.aes_password,
      aes_office: values.aes_office,
      export_pad_url: values.export_pad_url,
      create_export_pad_url: values.create_export_pad_url,
    };

    var jsonData = JSON.stringify(data);
    try {
      setLoading(true);
      const content = await UpdateOffices(jsonData, id);

      if (content.error) {
        handleError(content.error);
      } else if (content.status === "OK") {
        navigate("/office");
      }
    } catch {
      handleError("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = window.location.href;
        const newId = url.split("/").pop();
        setId(newId);
        const content = await FindOffice(newId);
        setOffice(content.data);

        setLoading(false);
      } catch (error) {
        handleError(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}
      >
        {error && (
          <div className="error-container">
            <span className="error-text">{errorDescription}</span>
          </div>
        )}
      </div>
      {office != null && (
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="form-container">
              <Formik
                validationSchema={validation}
                initialValues={{
                  siiv_username: office.siiv_username,
                  siiv_password: office.siiv_password,
                  siiv_office: office.siiv_office,
                  office_name: office.office_name,
                  functionality: office.functionality,
                  aes_username: office.aes_username,
                  aes_password: office.aes_password,
                  aes_office: office.aes_office,
                  export_pad_url: office.export_pad_url,
                  create_export_pad_url: office.create_export_pad_url,
                }}
                onSubmit={(values) => {
                  submitForm(values);
                }}
              >
                {() => (
                  <Form
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div className="form-position">
                      {form.map((snapshot, key) => {
                        return <Field {...snapshot} key={key} />;
                      })}
                    </div>
                    <button className="submit-form" type="submit">
                      {loading ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Save changes"
                      )}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
