import * as React from "react";
import { Formik, Field, Form } from "formik";
import { TextFormField } from "../../../components/textform/TextFormField.tsx";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { CreateNewTemplate } from "../../../services/template.js";
import PopSnackbar from "../../../components/snackbar/Snackbar.js";
import CircularProgress from "@mui/material/CircularProgress";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

export const form = [
  {
    name: "name",
    label: "Name",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "exporter",
    label: "Exporter",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "declarant",
    label: "Declarant",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "representative_number",
    label: "Representative Number",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "representative_status",
    label: "Representative Status",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "nature_of_transaction",
    label: "Nature of Transaction",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "country_of_export",
    label: "Country of Export",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "country_of_destination",
    label: "Country of Destination",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "delivery_terms_incoterm_code",
    label: "Delivery Terms Incoterm Code",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "delivery_terms_location",
    label: "Delivery Terms Location",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "delivery_terms_country",
    label: "Delivery Terms Country",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "consignee_name",
    label: "Consignee Name",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "consignee_street_and_number",
    label: "Consignee Street and Number",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "consignee_postcode",
    label: "Consignee Postcode",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "consignee_city",
    label: "Consignee City",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "consignee_country",
    label: "Consignee Country",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
];

export const validation = yup.object().shape({
  name: yup.string().required("Name is required"),
  exporter: yup.string().required("Exporter is required"),
  declarant: yup.string().required("Declarant is required"),
  representative_number: yup
    .string()
    .required("Representative Number is required"),
  representative_status: yup
    .string()
    .required("Representative Status is required"),
  nature_of_transaction: yup
    .string()
    .required("Nature of Transaction is required"),
  country_of_export: yup.string().required("Country of Export is required"),
  country_of_destination: yup
    .string()
    .required("Country of Destination is required"),
  delivery_terms_incoterm_code: yup
    .string()
    .required("Delivery Terms Incoterm Code is required"),
  delivery_terms_location: yup
    .string()
    .required("Delivery Terms Location is required"),
  delivery_terms_country: yup
    .string()
    .required("Delivery Terms Location is required"),
  consignee_name: yup.string().required("Consignee Name is required"),
  consignee_street_and_number: yup
    .string()
    .required("Consignee Street and Number is required"),
  consignee_postcode: yup.string().required("Consignee Postcode is required"),
  consignee_city: yup.string().required("Consignee City is required"),
  consignee_country: yup.string().required("Consignee Country is required"),
});

export default function CreateTemplate({ selectedOfficeID }) {
  const [error, setError] = useState(false);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  const navigate = useNavigate();
  const defaultLogoImage = require("../../../assets/logo-template-test.jpg");

  const handleFileRead = async (event) => {
    return await convertBase64(event);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (file) fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleStatus = (description, setStatus, setDescription) => {
    setStatus(true);
    setDescription(description);
    setTimeout(() => {
      setStatus(false);
      setDescription("");
    }, 5000);
  };

  const submitForm = async (values, isLoading = true) => {
    let photo = defaultLogoImage;

    if (file) {
      photo = await handleFileRead(file);
    }

    const data = {
      name: values.name,
      exporter: values.exporter,
      declarant: values.declarant,
      representative_number: values.representative_number,
      representative_status: values.representative_status,
      nature_of_transaction: values.nature_of_transaction,
      country_of_export: values.country_of_export,
      country_of_destination: values.country_of_destination,
      delivery_terms_incoterm_code: values.delivery_terms_incoterm_code,
      delivery_terms_location: values.delivery_terms_location,
      delivery_terms_country: values.delivery_terms_country,
      consignee_name: values.consignee_name,
      consignee_street_and_number: values.consignee_street_and_number,
      consignee_postcode: values.consignee_postcode,
      consignee_city: values.consignee_city,
      consignee_country: values.consignee_country,
      logo_image: photo,
    };

    var jsonData = JSON.stringify(data);
    try {
      isLoading && setLoading(true);

      const content = await CreateNewTemplate(jsonData, selectedOfficeID);

      if (content.status !== "OK") {
        throw new Error(
          content.error || "Something went wrong, please try again later."
        );
      }
    } catch (error) {
      handleStatus(error.message, setError, setDescription);
    } finally {
      isLoading && setLoading(false);
      navigate("/templates");
    }
  };

  const handleButtonClick = () => {
    document.querySelector("#file-input").click();
  };

  if (loading) return <CircularProgress />;
  if (error) return <PopSnackbar message={description} />;

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="upload-container">
          <div className="input-circle" onClick={handleButtonClick}>
            <input
              type="file"
              id="file-input"
              name="photo"
              className="input-field"
              accept="image/*"
              onChange={(event) => {
                setFile(event.target.files[0]);
              }}
            />
            <div className="inner-circle">
              {file ? (
                <div className="inner-circle-img-container">
                  <img
                    src={URL.createObjectURL(file)}
                    alt="preview"
                    className="inner-circle-img"
                  />
                  <div className="add-photo-overlay">
                    <AddAPhotoIcon />
                    <span className="upload-text">Update photo</span>
                  </div>
                </div>
              ) : (
                <>
                  <img
                    src={defaultLogoImage}
                    alt="default"
                    className="inner-circle-img"
                  />
                  <div className="add-photo-overlay">
                    <AddAPhotoIcon />
                    <span className="upload-text">Upload photo</span>
                  </div>
                </>
              )}
            </div>
          </div>
          <span className="upload-text-2">
            Allowed *.jpeg, *.jpg, *.png
            <br />
            max size of 3.1 MB
          </span>
        </div>

        <div className="form-container">
          <Formik
            validationSchema={validation}
            initialValues={{
              name: "",
              exporter: "",
              declarant: "",
              representative_number: "",
              representative_status: "",
              nature_of_transaction: "",
              country_of_export: "",
              country_of_destination: "",
              delivery_terms_incoterm_code: "",
              delivery_terms_location: "",
              delivery_terms_country: "",
              consignee_name: "",
              consignee_street_and_number: "",
              consignee_postcode: "",
              consignee_city: "",
              consignee_country: "",
              logo_image: "",
            }}
            onSubmit={(values) => {
              submitForm(values);
            }}
          >
            {() => (
              <Form
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className="form-position">
                  {form.map((snapshot, key) => {
                    return <Field {...snapshot} key={key} />;
                  })}
                </div>
                <button className="submit-form" type="submit">
                  {loading ? <CircularProgress size={24} /> : "Create Template"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
