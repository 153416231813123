import * as React from "react";
import { Formik, Field, Form } from "formik";
import { TextFormField } from "../../components/textform/TextFormField.tsx";
import * as yup from "yup";
import YupPassword from "yup-password";
import { FindUser, UpdateUsers, UpdateUsersPass } from "../../services/user.js";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MultiSelectFormField from "../../components/multiselectform/MultiSelectFormField.tsx";
import CircularProgress from "@mui/material/CircularProgress";

YupPassword(yup);

export const form = [
  {
    name: "name",
    label: "Full Name",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "email",
    label: "Email",
    disabled: true,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "company_name",
    label: "Company",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "role",
    label: "Role",
    disabled: false,
    component: MultiSelectFormField,
    select: true,
    options: [
      { value: "ROLE_ADMIN", label: "Admin" },
      { value: "ROLE_USER", label: "User" },
    ],
    style: {
      width: "49%",
    },
  },
];

export const validation = yup.object().shape({
  name: yup.string().required("Full Name is required"),
  email: yup
    .string()
    .required("Email is required")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),
  company_name: yup.string().required("Company is required"),
  role: yup.array().min(1, "At least one role is required"),
});

export const formPass = [
  {
    name: "password",
    label: "Password",
    disabled: false,
    type: "password",
    component: TextFormField,
    style: {
      width: "70%",
    },
  },
  {
    name: "confirm_password",
    label: "Confirm Password",
    disabled: false,
    type: "password",
    component: TextFormField,
    style: {
      width: "70%",
    },
  },
];

export const validationPass = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(
      8,
      "password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
    )
    .minLowercase(1, "password must contain at least 1 lower case letter")
    .minUppercase(1, "password must contain at least 1 upper case letter")
    .minNumbers(1, "password must contain at least 1 number")
    .minSymbols(1, "password must contain at least 1 special character"),
  confirm_password: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export default function UpdateUser() {
  const [error, setError] = useState(false);
  const [errorDescription, setErrorDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [user, setUser] = useState(null);
  const [togglePassForm, setTogglePassForm] = useState(false);
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  const handleFileRead = async (event) => {
    return await convertBase64(event);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (file) fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleError = (description) => {
    setError(true);
    setErrorDescription(description);
  };

  const submitForm = async (values) => {
    var base64 = null;
    if (file != null) {
      base64 = await handleFileRead(file);
    } else {
      base64 = user.photo;
    }

    const data = {
      name: values.name,
      email: values.email,
      role: values.role,
      company_name: values.company_name,
      photo: base64,
    };

    var jsonData = JSON.stringify(data);
    try {
      setLoading(true);
      const content = await UpdateUsers(jsonData, id);

      if (content.error) {
        handleError(content.error);
      } else if (content.status === "OK") {
        navigate("/user");
      }
    } catch {
      handleError("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleButtonClick = (event) => {
    document.querySelector("#file-input").click();
  };

  const submitFormPass = async (values) => {
    const data = {
      password: values.password,
    };
    var jsonData = JSON.stringify(data);
    try {
      setLoading(true);
      const content = await UpdateUsersPass(jsonData, id);

      if (content.error) {
        handleError(content.error);
      } else if (content.status === "OK") {
        alert("Password updated");
        setTogglePassForm(false);
      }
    } catch {
      handleError("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = window.location.href;
        const newId = url.split("/").pop();
        setId(newId);
        const content = await FindUser(newId);
        setUser(content.data);

        setLoading(false);
      } catch (error) {
        handleError(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}
      >
        {error && (
          <div className="error-container">
            <span className="error-text">{errorDescription}</span>
          </div>
        )}
      </div>
      {user != null && (
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="upload-container">
              <div className="input-circle" onClick={handleButtonClick}>
                <input
                  type="file"
                  id="file-input"
                  name="photo"
                  className="input-field"
                  accept="image/*"
                  onChange={(event) => {
                    setFile(event.target.files[0]);
                  }}
                />

                <div className="inner-circle">
                  <img
                    src={file ? URL.createObjectURL(file) : user.photo}
                    alt="preview"
                    className="inner-circle-img"
                  ></img>
                  <div className="hover-overlay">
                    <AddAPhotoIcon
                      sx={{
                        width: "32px",
                        height: "32px",
                      }}
                    />
                    <span className="upload-text">Update photo</span>
                  </div>
                </div>
              </div>
              <span className="upload-text-2">
                Allowed *.jpeg, *.jpg, *.png
                <br />
                max size of 3.1 MB
              </span>
            </div>
            <div className="form-container">
              <Formik
                validationSchema={validation}
                initialValues={{
                  name: user.name,
                  email: user.email,
                  photo: "",
                  company_name: user.company_name,
                  role: user.role,
                }}
                onSubmit={(values) => {
                  submitForm(values);
                }}
              >
                {() => (
                  <Form
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div className="form-position">
                      {form.map((snapshot, key) => {
                        return <Field {...snapshot} key={key} />;
                      })}
                    </div>
                    <button className="submit-form" type="submit">
                      {loading ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Save changes"
                      )}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="update-password-container">
          <button
            className="toggle-pass-btn"
            onClick={() => setTogglePassForm(!togglePassForm)}
          >
            Update Password
            {!togglePassForm ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowUpIcon />
            )}
          </button>
          <div
            style={{
              height: "auto",
              maxHeight: togglePassForm ? "300px" : 0,
              overflow: "hidden",
              transition: "max-height 0.5s",
            }}
          >
            <Formik
              validationSchema={validationPass}
              initialValues={{
                password: "",
                confirm_password: "",
              }}
              onSubmit={(values) => {
                submitFormPass(values);
              }}
            >
              {() => (
                <Form
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className="form-position">
                    {formPass.map((snapshot, key) => {
                      return <Field {...snapshot} key={key} />;
                    })}
                  </div>
                  <button
                    className="submit-form"
                    type="submit"
                    style={{ marginBottom: "0px" }}
                  >
                    {loading ? <CircularProgress size={24} /> : "Save Changes"}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
