import React, { useState } from "react";
import { TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { ReqLogin } from "../../services/auth";
import logo from "../../assets/logo-tcs.png";
import { Phone, Email } from "@mui/icons-material";

export default function Login() {
  const [buttonLoading, setButtonLoading] = useState(false);

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [error, setError] = useState(false);
  const [errorDescription, setErrorDescription] = useState();

  function HasUpperCase(str) {
    return /[A-Z]/.test(str);
  }

  function HandleError(message) {
    setButtonLoading(false);
    setError(true);
    setErrorDescription(message);
    setTimeout(() => {
      setError(false);
      setErrorDescription(message);
    }, 3000);
  }

  async function SubmitLogin() {
    setButtonLoading(true);
    if (!email || !password) {
      HandleError("Please enter both email and password");
      return;
    }

    const email_formated = email.toLowerCase();
    const regex_email =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const email_verification = regex_email.test(email_formated);
    const password_verification = HasUpperCase(password) && password.length > 4;

    if (!email_verification) {
      HandleError("Email is not valid");
    } else if (!password_verification) {
      HandleError("Password is not valid");
    } else {
      const body = {
        email: email_formated,
        password,
      };
      const content = await ReqLogin(JSON.stringify(body));

      if (content.error) {
        HandleError(content.error);
      } else if (content.status === "OK") {
        localStorage.setItem("token", content.token);
        setButtonLoading(false);
        window.location.replace("/");
      } else {
        HandleError("Something went wrong, please try again later.");
      }
    }
  }

  return (
    <div className="login-body">
      <div className="illustration">
        <div className="logo-gsd">
          <img src={logo} alt="Logo" height="50" />
        </div>
        <div className="text-container">
          <h1 style={{ color: "black" }}>Hi, Welcome back</h1>
          <div className="support-text-container">
            <div style={{ marginBottom: 10 }}>
              <h3 style={{ fontSize: 20, marginBottom: 5 }}>Need Help?</h3>
              <p style={{ fontSize: 15 }}>
                Our support team is here to assist you. Reach out to us for any
                inquiries or assistance you might need regarding our services.
              </p>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", marginBottom: 5 }}
            >
              <Email style={{ marginRight: 8, width: 18, height: 18 }} />
              <span style={{ fontFamily: "SeagoeUI" }}>
                Email: help@kodingtech.com
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Phone style={{ marginRight: 8, width: 18, height: 18 }} />
              <span>Phone: +40.731.214.296</span>
            </div>

            <div
              style={{ display: "flex", alignItems: "center", marginTop: 10 }}
            >
              <span>
                Made with love by{" "}
                <a
                  style={{ textDecoration: "none" }}
                  href="https://kodingtech.com"
                >
                  KodingTech@2024
                </a>
              </span>
            </div>
          </div>
        </div>

        <div className="illustration-png"></div>
      </div>

      <div className="loginbox2" style={{ zIndex: 1 }}>
        <h1 style={{ fontSize: "1.5rem", marginTop: 60 }}>
          Sign in to our app
        </h1>
        <div className="signin-img"></div>
        <div className="login-right2">
          <div>
            <TextField
              style={{ width: "100%", marginTop: 10 }}
              variant="outlined"
              label={"Email address"}
              onChange={(snap) => setEmail(snap.target.value)}
              InputProps={{
                style: {
                  borderRadius: 10,
                },
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  SubmitLogin();
                }
              }}
            />

            <TextField
              style={{ width: "100%", marginTop: 10 }}
              variant="outlined"
              label={"Password"}
              type="password"
              onChange={(snap) => setPassword(snap.target.value)}
              InputProps={{
                style: {
                  borderRadius: 10,
                },
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  SubmitLogin();
                }
              }}
            />

            {error ? <p style={{ color: "red" }}>{errorDescription}</p> : null}

            <div
              style={{
                display: "flex",
                alignSelf: "center",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <div
                className="login-page-btn"
                onClick={SubmitLogin}
                disabled={buttonLoading}
              >
                {buttonLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Login"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
